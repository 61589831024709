<style lang="less" scoped>
.login {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: #070911;
  &-container {
    width: 400px;
    height: 367px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background: rgba(15, 17, 24, 1);
    font-size: 18px;
    font-family: "PingFangSC-Regular", "PingFang SC";
    font-weight: 400;
    color: rgba(85, 119, 224, 1);
    .el-input {
      margin-top: 16px;
    }
    &-button {
      width: 140px;
      height: 40px;
      margin: 0 auto;
      margin-top: 30px;
      text-align: center;
      line-height: 40px;
      letter-spacing: 0;
      cursor: pointer;
      background: linear-gradient(
        211deg,
        rgba(248, 40, 108, 1) 0%,
        rgba(249, 98, 47, 1) 100%
      );
      border-radius: 29px;
      font-weight: 600;
      color: rgba(255, 255, 255, 1);
    }
    &-title {
      font-size: 18px;
      font-weight: 400;
      color: rgba(85, 119, 224, 1);
      font-family: "PingFangSC-Regular", "PingFang SC";
      line-height: 25px;
      margin-top: 35px;
    }
  }
  .sn-code {
    width: 340px;
    display: inline-flex;
    img {
      width: 40%;
      height: 40px;
    }
  }
}
</style>

<template>
  <div class="login">
    <div class="login-container"
         :style="{height: '227px'}">
      <div class="login-container-new">
        <div class="login-container-title">
          {{$t('resetPassword')}}
        </div>
        <el-input v-model="loginEmail"
                  :placeholder="$t('enterEmail')"
                  :style="{width: '340px'}"></el-input>
        <div class="login-container-button"
             @click="reset">
          {{$t('reset')}}
        </div>
      </div>
    </div>
    <el-dialog :title="$t('P18')"
               :visible.sync="dialogVisible"
               width="30%">
      <div class="sn-code">
        <el-input v-model="loginSncode"
                  :placeholder="$t('enterCode')"
                  :style="{width: '240px'}"></el-input>
        <img :src="snCodeUrl"
             :title="$t('P17')"
             :alt="$t('P17')"
             @click="changeSnCodeUrl">
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="resetSure">{{$t('confirm')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

const tornodaBaseURL = '/api';

export default {
  name: 'login',
  data () {
    return {
      confirmPassword: '',
      snCode: '',
      codeUrl: '',
      loginEmail: '',
      loginSncode: '',
      dialogVisible: false
    }
  },
  mounted () {
  },
  computed: {
    snCodeUrl () {
      if (this.codeUrl) {
        return tornodaBaseURL + '/captcha/' + this.codeUrl;
      } else {
        return '';
      }
    }
  },
  created () {
    // this.codeUrl = tornodaBaseURL + '/captcha/' + this.codeUrl;
  },
  methods: {
    getCaptchaToken () {
      this.$api.getCaptcha_token({
        id: this.loginEmail
      }).then(res => {
        this.codeUrl = res.token;
      })
    },
    changeSnCodeUrl () {
      this.getCaptchaToken()
    },
    reset () {
      if (this.loginEmail.trim() === '') {
        this.$message.warning('请输入邮箱！');
        return;
      }
      this.$api.getCaptcha_token({
        id: this.loginEmail
      }).then(res => {
        this.codeUrl = res.token;
        this.dialogVisible = true;
      })
    },
    resetSure () {
      this.$api.postReset_password_request({
        email: this.loginEmail,
        token: this.codeUrl,
        captcha: this.loginSncode
      }).then(() => {
        this.dialogVisible = false;
        this.$message.success('发送重置请求成功，请去邮箱查看链接');
      }).catch(error => {
        this.$message.error(error.response.data.msg);
      })
    }
  }
}
</script>
